<template>
  <section class="invoice-preview-wrapper">

   

    <b-row
      v-if="Model"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                 
                  <h3 class="text-primary mt-1 invoice-logo">
                   
                    {{ reservedMedicalPackage.MedicalPackage.Name }}
                  </h3>
                  

                </div>

                <h4 class="text-primary invoice-logo"
          v-html="reservedMedicalPackage.MedicalPackage.Description">  </h4>
                <p class="card-text mb-25">
                  {{ $t("DurationByDay") }} : {{ reservedMedicalPackage.DurationByDay }}
              </p>
               
              </div>

              
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-card-body
           
            class="invoice-padding pt-0 m-4"
          >
            <b-row class="invoice-spacing">

              <!-- Col: MedicalPackageIncludedItems To -->
              <b-col
              v-if="(reservedMedicalPackage.MedicalPackage.MedicalPackageIncludedItems!=null && reservedMedicalPackage.MedicalPackage.MedicalPackageIncludedItems!=undefined&& reservedMedicalPackage.MedicalPackage.MedicalPackageIncludedItems.length>0)"
                cols="12"
                xl="6"
                class="p-0"
              >
              <h3 class="text-primary invoice-logo">
                  {{ $t("ThisPackageincludes") }}
                </h3>
                <b-row
            class="vx-row ml-10"
            v-for="item in (reservedMedicalPackage.MedicalPackage
            .MedicalPackageIncludedItems)"
            :key="item.ID"
          >
          <p class="circle primaryBackColor mt-1 mr-2"></p>  <span class=" mt-1">{{ item.Name }}</span>
            <!-- <h5>
              <p>{{ item.Name }}</p>
            </h5> -->
          </b-row>
              </b-col>

              <!-- Col: MedicalPackageExcludedItems -->
              <b-col
              v-if="(reservedMedicalPackage.MedicalPackage.MedicalPackageExcludedItems!=null && reservedMedicalPackage.MedicalPackage.MedicalPackageExcludedItems!=undefined&& reservedMedicalPackage.MedicalPackage.MedicalPackageExcludedItems.length>0)"
              cols="12"
                xl="6"
                class="p-0"
              >
                  <h3 class="text-primary invoice-logo">
                    {{ $t("ThisPackagedoesnotinclude") }}
                  </h3>
                  <b-row
                  class="vx-row ml-10"
            v-for="item in reservedMedicalPackage.MedicalPackage.MedicalPackageExcludedItems"
            :key="item.ID"
          >
          <p class="circle primaryBackColor mt-1 mr-2"></p>  <span class=" mt-1">{{ item.Name }}</span>
          </b-row>
              </b-col>
            </b-row>
          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <b-col>
          <p style="font-weight:bold">
            <span class="text-2xl" style="color: primary"
              >{{ $t("HowManyPassengers") }} :
            </span>
            <span>{{ reservedMedicalPackage.NumberOfCompanions }}</span>
          </p>
          <p style="font-weight:bold">
            <span class="text-2xl" style="color: primary"
              >{{ $t("NumberOfBeneficiary") }} :
            </span>
            <span>{{ reservedMedicalPackage.NumberOfBeneficiary }}</span>
          </p>
          <p style="font-weight:bold">
            <span class="text-2xl" style="color: primary"
              >{{ $t("Date") }} :</span
            >
            <span v-if="reservedMedicalPackage.ReservedDate">{{
              reservedMedicalPackage.ReservedDate.split("T")[0]
            }}</span>
            <span
              v-if="
                reservedMedicalPackage.PreferrdDateFrom &&
                reservedMedicalPackage.PreferrdDateTo
              "
              >{{
                reservedMedicalPackage.PreferrdDateFrom.split("T")[0] +
                "-" +
                reservedMedicalPackage.PreferrdDateTo.split("T")[0]
              }}</span
            >
            </p>
          <b-button
            color="rgb(255, 255, 255)"
            class="text-2xl w-full"
            text-color="rgb(0, 156, 255)"
          >
            {{ $t("Price") }}: {{ reservedMedicalPackage.PriceInUSD }} $
          </b-button>
          <b-button
            color="rgb(255, 255, 255)"
            class="text-2xl w-full mt-2"
            text-color="rgb(0, 156, 255)"
            v-if="reservedMedicalPackage.Status"
          >
            {{ $t("Status") }}: {{ reservedMedicalPackage.Status.Name }}
          </b-button>
          <b-button
            color="rgb(255, 255, 255)"
            class="text-2xl w-full mt-2"
            text-color="rgb(0, 156, 255)"
            v-if="reservedMedicalPackage.PaymentStatus"
          >
            {{ $t("Payment") }}: {{ reservedMedicalPackage.PaymentStatus.Name }}
          </b-button>


          <b-button type="button" class="text-xl w-full mt-2 btn btn-danger"   
          @click="CancelReservedMedicalPackage()"
          background="rgb(255, 255, 255)"
          text-color="red"
          style="width: 100%; height: 50px; color: whitesmoke; border-radius: 1px; background-color: red;"
          v-if="reservedMedicalPackage.Status.Name=='Pending'"
          >
          {{ $t("CancelreservedMedicalPackage") }}
          </b-button>

        </b-col>
        </b-card>
      </b-col>
    </b-row>
    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
   
  </section>
</template>
<script>
import moduleReservedPackage from "@/store/reservedPackage/moduleReservedPackage.js";
import { BAlert, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite } from 'bootstrap-vue';
import point from "../../../components/point.vue";
export default {
  components: {
    point,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

  },
  data() {
    return {
      EnglishLan: false,
      ShowConfirmation: false,
      Model: {},
      reservedMedicalPackage: {},
    };

  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    CancelReservedMedicalPackage()
    {
      debugger;
      var reservedMedicalPackageID= this.$route.params.PackageID;
      this.$store.dispatch("ReservatedPackage/CancelReservedMedicalPackage", reservedMedicalPackageID)
      .then((res) => {
          if (res.status == 200) {
            debugger;
            window.showSuccess()
            this.$router.push("/patient/home");
          }
        })
        .catch(() => {
          window.showError();
        });
    }
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleReservedPackage.isRegistered) {
      this.$store.registerModule("ReservatedPackage", moduleReservedPackage);
      moduleReservedPackage.isRegistered = true;
    }
    debugger;
    var id = this.$route.params.PackageID;
    if (id) {
      this.$store
        .dispatch("ReservatedPackage/GetReservedMedicalPackage", id)
        .then((res) => {
          this.reservedMedicalPackage = res.data.Data;
        });
    }
  },
};
</script>
<style >
.customInput {
  border-radius: 10px;
  padding: 5px;
  height: 40px;
}
.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.Border {
  border-radius: 10px;
}
</style>