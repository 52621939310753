/*=========================================================================================
  File Name: moduleReservedPackageMutations.js
  Description: ReservedPackage Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.reservedPackages.unshift(item)
  },
  SET_ReservedPackage(state, reservedPackages) {
    state.reservedPackages = reservedPackages
  },
  UPDATE_ReservedPackage(state, ReservedPackage) {
    const ReservedPackageIndex = state.reservedPackages.findIndex((p) => p.ID == ReservedPackage.ID)
    Object.assign(state.reservedPackages[ReservedPackageIndex], ReservedPackage)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.reservedPackages.findIndex((p) => p.ID == itemId)
    state.reservedPackages.splice(ItemIndex, 1)
},
}
